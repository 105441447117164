.rdrDateDisplayWrapper {
    background-color: white;
}
.rdrDateDisplayItemActive input {
    
    color: #40464E;
}

.rdrCalendarWrapper {
    width: 500px;
}
.rdrDefinedRangesWrapper {
    display: none;
}