:root {
  --tooltip-text-color: white;
  --tooltip-background-color: #3e3e3e;
  --tooltip-text-color-black: #3e3e3e;
  --tooltip-background-color-white: white;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  padding: 10px;
  border-radius: 4px;
  left: 50%;
  transform: translate(-50%, 50%);
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

.Tooltip-Tip-white {
  position: absolute;
  padding: 10px;
  border-radius: 4px;
  left: 100%;
  top: -35%;
  color: var(--tooltip-text-color-black);
  background: var(--tooltip-background-color-white);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 500;
  white-space: nowrap;
  box-shadow: 0px 2px 2px 0px rgba(55, 55, 55, 0.02);

  box-shadow: 0px 0px 8px 0px rgba(100, 100, 100, 0.2);
}

.Tooltip-Tip.click {
  padding: 20px;
}

.Tooltip-Tip .close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.Tooltip-Tip .close:hover {
  opacity: 1;
}
.Tooltip-Tip .close:before,
.Tooltip-Tip .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.Tooltip-Tip .close:before {
  transform: rotate(45deg);
}
.Tooltip-Tip .close:after {
  transform: rotate(-45deg);
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: ' ';
  left: 50%;

  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}
