@media print {
  html body .print-none {
    display: none;
  }
  footer {
    page-break-after: always;
  }
  body .print-area {
    visibility: visible;
    position: absolute;
    display: block;
    -webkit-print-color-adjust: exact !important;
    width: 100%;
  }

  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  /* tfoot {
    display: table-footer-group;
  } */

  body .pagebreak {
    break-after: always;
    clear: both;
  }

  /* page-break-after works, as well */

  /* @page {
    size: 210mm 297mm;
    padding-bottom: 29mm;
    margin: 27mm 16mm 27mm 16mm;
  }

  @page front-matter :left {
    @bottom-left {
      content: counter(page, lower-roman);
    }
  } */
}
