.dropDown-list:last-child {
  color: #ec465c;
}

.status-wrapper .complete,
.new_order,
.upcoming_order {
  background-color: #e7f5e6;
  color: #42b139;
}

.status-wrapper .waiting_driver,
.waiting_payment {
  background-color: #ffeaef;
  color: #ff5c84;
}

.status-wrapper .picking,
.packing {
  background-color: #fff0b3;
  color: #fa591d;
}

.status-wrapper .on_delivery,
.status-wrapper .arrived {
  background-color: #ddd19f;
  color: #c26949;
}

.status-wrapper .cancelled {
  background-color: #e4ebf5;
  color: #6d7588;
}

.status-wrapper .payment_expired {
  background-color: #e4ebf5;
  color: #6d7588;
}

.status-wrapper .partially_fulfilled {
  background-color: #e4ebf5;
  color: #6d7588;
}

.status-wrapper .out_of_stock {
  background-color: #e4ebf5;
  color: #6d7588;
}

.all-order-action .menu-action-list:last-child > p {
  color: #ec465c !important;
}

.status-wrapper .waiting_confirmation {
  background-color: #feedef;
  color: #f94d63;
}
