.scheduled {
  background: #c5fcff;
  color: #0479b9;
}

.on_going {
  background: #c9fde0;
  color: #098a4e;
}
.ended {
  background: #ffdbe2;
  color: #f94d63;
}
.inactive {
  background: #e4ebf5;
  color: #6d7588;
}
