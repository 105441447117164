@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?y9j1iu');
  src: url('fonts/icomoon.eot?y9j1iu#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?y9j1iu') format('truetype'),
    url('fonts/icomoon.woff?y9j1iu') format('woff'),
    url('fonts/icomoon.svg?y9j1iu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-payroll:before {
  content: '\e938';
  color: #646464;
}
.icon-capacity:before {
  content: '\e936';
  color: #646464;
}
.icon-fresh:before {
  content: '\e935';
}
.icon-refund:before {
  content: '\e934';
  color: #525a67;
}
.icon-chevron-left:before {
  content: '\e932';
}
.icon-chevron-right:before {
  content: '\e933';
}
.icon-staff-driver:before {
  content: '\e931';
}
.icon-information:before {
  content: '\e930';
  color: #aab4c8;
}
.icon-outbound:before {
  content: '\e92e';
  color: #1553b6;
}
.icon-campaign:before {
  content: '\e92c';
  color: #8d96aa;
}
.icon-store:before {
  content: '\e92b';
  color: #52565e;
}
.icon-pin:before {
  content: '\e92a';
  color: #525a67;
}
.icon-edit-v2:before {
  content: '\e929';
  color: #525a67;
}
.icon-box:before {
  content: '\e928';
  color: #246ee5;
}
.icon-filter:before {
  content: '\e927';
  color: #c4c4c4;
}
.icon-delete-v2:before {
  content: '\e92d';
  color: #aab4c8;
}
.icon-calendar-v2:before {
  content: '\e925';
  color: #aab4c8;
}
.icon-persentage:before {
  content: '\e926';
  color: #aab4c8;
}
.icon-rack:before {
  content: '\e924';
  color: #aab4c8;
}
.icon-hamburger:before {
  content: '\e920';
}
.icon-threeDots:before {
  content: '\e921';
  color: #aab4c8;
}
.icon-contact:before {
  content: '\e922';
  color: #aab4c8;
}
.icon-clockFull:before {
  content: '\e91a';
  color: #aab4c8;
}
.icon-calendar:before {
  content: '\e91b';
  color: #aab4c8;
}
.icon-location:before {
  content: '\e91c';
  color: #aab4c8;
}
.icon-upload:before {
  content: '\e91d';
  color: #aab4c8;
}
.icon-group:before {
  content: '\e91e';
}
.icon-printer:before {
  content: '\e919';
  color: #aab4c8;
}
.icon-card:before {
  content: '\e901';
}
.icon-time:before {
  content: '\e902';
}
.icon-checklist:before {
  content: '\e903';
}
.icon-up-and-down:before {
  content: '\e900';
  color: #aab4c8;
}
.icon-arrow-left:before {
  content: '\e904';
}
.icon-product:before {
  content: '\e905';
}
.icon-chat:before {
  content: '\e906';
}
.icon-check:before {
  content: '\e907';
}
.icon-dropdown:before {
  content: '\e908';
}
.icon-person:before {
  content: '\e909';
}
.icon-plus:before {
  content: '\e90a';
}
.icon-search:before {
  content: '\e90b';
}
.icon-dashboard:before {
  content: '\e90c';
}
.icon-cart:before {
  content: '\e90d';
}
.icon-statistic:before {
  content: '\e90e';
}
.icon-notification:before {
  content: '\e90f';
}
.icon-open-eye:before {
  content: '\e910';
}
.icon-close-eye:before {
  content: '\e911';
}
.icon-trash:before {
  content: '\e912';
}
.icon-logout:before {
  content: '\e913';
}
.icon-setting:before {
  content: '\e914';
}
.icon-promotion:before {
  content: '\e923';
  color: #aab4c8;
}
.icon-inBox:before {
  content: '\e92f';
  color: #1553b6;
}
.icon-attachment:before {
  content: '\e9cd';
}
.icon-stock-opname:before {
  content: '\e937';
  color: #aab4c8;
}
.icon-arrowv2:before {
  content: '\e91f';
  color: #aab4c8;
}
.icon-edit:before {
  content: '\e915';
  color: #00ba6c;
}
.icon-image:before {
  content: '\e916';
  color: #f08227;
}
.icon-category:before {
  content: '\e917';
  color: #00ba6c;
}
.icon-flag:before {
  content: '\e918';
}
.icon-microsite:before {
  content: '\e93a';
  color: #646464;
}
