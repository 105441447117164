.rdrDateDisplayWrapper {
  background-color: white;
}
.rdrDateDisplayItemActive input {
  color: #40464e;
}

.isSingleDate .rdrCalendarWrapper {
  width: 100%;
}

.rdrDefinedRangesWrapper {
  display: none;
}
